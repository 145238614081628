import { RequestOtpResponseTypes, VerifyOtpParamsTypes } from './type';

import axiosInstance from 'services/common/instance';

const requestOtpService = async (params:{ phone: string}): Promise<RequestOtpResponseTypes> => {
  const res = await axiosInstance.post('customer/request-otp', params);
  return res.data.data;
};

export const verifyOtpService = async (params: VerifyOtpParamsTypes) => {
  const res = await axiosInstance.post('customer/verify-otp', params);
  return res.data;
};

export default requestOtpService;
