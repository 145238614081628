/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

import LoginPg, { LoginPgFormTypes } from 'components/templates/LoginPg';
import { useAsync } from 'hooks/useAsync';
import { LoginPgParamsTypes } from 'services/auth/type';
import getSuperMarketListService from 'services/supermarket';
import { SuperMarketListTypes } from 'services/supermarket/type';
import { getLoginPgAction, getProfilePgAction } from 'store/Auth';
import { updateFlowAction } from 'store/System';
import { useAppDispatch } from 'store/hooks';
import SLUG_PARAMS, { TOAST_CONFIG } from 'utils/constants';
import { loginPgSchema } from 'utils/schemas';

const LoginPGContainer: React.FC = () => {
  const [smList, setSmList] = useState<SuperMarketListTypes[]>();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const method = useForm<LoginPgFormTypes>({
    resolver: yupResolver(loginPgSchema),
  });

  // LOGIN ASYNC
  const [loginExcute, loginState] = useAsync(
    (params: LoginPgParamsTypes) => dispatch(getLoginPgAction(params)).then(unwrapResult),
    {
      onSuccess: () => {
        dispatch(updateFlowAction('PG'));
        dispatch(getProfilePgAction());
        navigation(SLUG_PARAMS.CUSTOMER_INFO);
      },
      onFailed: (err) => {
        const errors = err as ErrorResponse[];
        if (Array.isArray(errors)) {
          errors.forEach((val) => {
            if (val.field === 'phone') {
              method.setError('phone', { message: 'Số điện thoại không tồn tại' });
            } else {
              method.setError(val.field as any, { message: val.message });
              toast.error('Đã có lỗi xảy ra!', TOAST_CONFIG as ToastOptions);
            }
          });
        }
      },
    },
  );

  const handleSubmitLogin = (data: LoginPgFormTypes) => {
    loginExcute({
      supermarket_id: Number(data.supermarket.value),
      phone: data.phone,
      password: data.password,
    });
    (window as any).constellar?.action('login', {
      supermarket_id: Number(data.supermarket.value),
      phone: data.phone,
      password: data.password,
    });
  };

  useEffect(() => {
    (async () => {
      const res = await getSuperMarketListService();
      setSmList(res);
    })();
  }, []);

  const superMarketList = useMemo(() => (smList ? smList.map((val) => ({
    value: val.id.toString(),
    label: val.name,
  })) : []), [smList]);

  return (
    <>
      <LoginPg
        supermarketList={superMarketList}
        method={method}
        onSubmit={handleSubmitLogin}
        isLoading={loginState.loading}
      />
      <ToastContainer />
    </>
  );
};

export default LoginPGContainer;
