import React, { useCallback, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Form from 'components/organisms/Form';
import useDebounce from 'hooks/useDebounce';
import { getPhoneInformationService } from 'services/auth';

export type LoginPgFormTypes = {
  supermarket: OptionType;
  employee: string;
  phone: string;
  password: string;
}

interface LoginProps {
  method: UseFormReturn<LoginPgFormTypes>;
  isLoading?: boolean;
  supermarketList?: OptionType[];
  onSubmit?: (data: LoginPgFormTypes)=>void;
}

const LoginPg: React.FC<LoginProps> = ({
  onSubmit, method, isLoading, supermarketList,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumberDeb = useDebounce(phoneNumber, 500);

  // VALIDATE PHONE
  const validateField = useCallback(async (value:string) => {
    if (value) {
      method.clearErrors();
      try {
        const res = await getPhoneInformationService(value);
        if (res) {
          method.setValue('employee', res.name);
        } else {
          method.setValue('employee', '');
        }
      } catch (error) {
        console.log('error');
      }
    } else {
      method.setError('phone', { message: '' });
    }
  }, [method]);

  useEffect(() => {
    validateField(phoneNumberDeb);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberDeb, validateField]);

  /**
   * HANDLE SUBMIT
   */
  const handleSubmit = (data: LoginPgFormTypes) => {
    if (onSubmit) onSubmit(data);
  };
  return (
    <div className="t-loginPg">
      <div className="t-loginPg_form">
        <Form method={method} submitForm={handleSubmit}>
          {
            supermarketList
          && (
          <div className="t-loginPg_form_field">
            <Controller
              name="supermarket"
              control={method.control}
              render={({ field, fieldState }) => (
                <Pulldown
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Chọn siêu thị"
                  options={supermarketList}
                  name="supermarket"
                  label="Tên siêu thị"
                  error={fieldState.error?.message}
                />
              )}
            />
          </div>
          )
          }
          <div className="t-loginPg_form_field">
            <Controller
              control={method.control}
              name="employee"
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="employee"
                  value={value}
                  type="text"
                  label="Tên nhân viên"
                  error={error?.message}
                  handleChange={onChange}
                  readOnly
                />
              )}
            />
          </div>
          <div className="t-loginPg_form_field">
            <Controller
              control={method.control}
              name="phone"
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="phone"
                  value={value}
                  type="tel"
                  label="Số điện thoại"
                  error={error?.message}
                  handleChange={(e) => {
                    onChange(e);
                    setPhoneNumber(e.currentTarget.value);
                  }}
                />
              )}
            />
          </div>
          <div className="t-loginPg_form_field">
            <Controller
              control={method.control}
              name="password"
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="password"
                  value={value}
                  type="password"
                  label="Mật khẩu"
                  error={error?.message}
                  handleChange={onChange}
                />
              )}
            />
          </div>
          <div className="t-loginPg_form_submit">
            <Button loading={isLoading} isSubmit>
              ĐĂNG NHẬP
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

LoginPg.defaultProps = {
  onSubmit: undefined,
};

export default LoginPg;
