/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';

import OtpPopup from '../OtpPopup';
import PolicyPopup from '../PolicyPopup';

import checkIcon from 'assets/icons/ic-check.png';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Input from 'components/atoms/Input';
import Form from 'components/organisms/Form';
import { useAsync } from 'hooks/useAsync';
import { createCustomerService } from 'services/auth';
import { CreateCustomerParamsTypes } from 'services/auth/type';
import { clearValueSession, getLocalStorage, setLocalStorage } from 'services/common/storage';
import requestOtpService, { verifyOtpService } from 'services/customer';
import { FlowType } from 'store/System/type';
import { useAppSelector } from 'store/hooks';
import SLUG_PARAMS, { LOCAL_PARAMS, TOAST_CONFIG } from 'utils/constants';
import { customerInfoSchema } from 'utils/schemas';

export type CustomerInfoFormTypes = {
  firstName: string;
  lastName: string;
  surName: string;
  phone: string;
  email: string;
  otp?: string;
}

interface CustomerInfoProps {
  flowType?: FlowType;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({
  flowType,
}) => {
  const { profile: profileData } = useAppSelector((state) => state.auth);
  const resendTimer = 60;
  const verifiedOtpRef = useRef(false);
  const [validOtp, setValidOtp] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [openPolicy, setOpenPolicy] = useState(false);
  const navigation = useNavigate();
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [confirmLoadingOtp, setConfirmLoadingOtp] = useState(false);
  const [inCountdownOTP, setInCountdownOTP] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(0);
  const [otpToken, setOtpToken] = useState<string>();
  const method = useForm<CustomerInfoFormTypes>({
    resolver: yupResolver(customerInfoSchema),
  });
  // const superMarketStorage = getLocalStorage(LOCAL_PARAMS.SUPERMARKET_VALUE);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);
    if (countdownTimer === 0) {
      setInCountdownOTP(false);
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [countdownTimer]);

  const [createCustomerExec, createCustomerState] = useAsync(async (
    params: CreateCustomerParamsTypes,
  ) => {
    const res = await createCustomerService(params);
    setLocalStorage(LOCAL_PARAMS.UUID, res.uuid);
  }, {
    onSuccess: () => {
      navigation(SLUG_PARAMS.CONGRATULATION);
    },
    onFailed: () => {
      toast.error('Đã có lỗi xảy ra!', TOAST_CONFIG as ToastOptions);
    },
  });

  /**
   * HANDLE SUBMIT
   */
  const handleSubmit = (data: CustomerInfoFormTypes) => {
    if (!verifiedOtpRef.current) {
      toast.error('Vui lòng xác nhận OTP!', TOAST_CONFIG as ToastOptions);
      return;
    }
    if (!checkPolicy) {
      toast.error('Xin đồng ý với điều khoản', TOAST_CONFIG as ToastOptions);
      return;
    }
    // submit here
    createCustomerExec({
      last_name: data.lastName,
      first_name: data.firstName,
      middle_name: data.surName,
      phone: data.phone,
      email: data.email,
      token: otpToken || '',
      supermarket_id: profileData?.currentLogin.id || 0,
    });
  };

  /**
   * Request OTP
   */
  const requestOTP = async () => {
    const phone = method.getValues('phone');
    try {
      setLoadingOtp(true);
      const res = await requestOtpService({ phone });
      if (res) {
        setOtpToken(res.data.token);
      }
      toast.success('Gửi OTP thành công!', TOAST_CONFIG as ToastOptions);
      setErrorOtp(false);
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => {
          setErrorOtp(true);
          if (err.status === 422) {
            toast.error('Số điện thoại đã nhận quà của chương trình', TOAST_CONFIG as ToastOptions);
          } else toast.error('Gửi OTP không thành công', TOAST_CONFIG as ToastOptions);
        });
      }
    } finally {
      setLoadingOtp(false);
    }
  };

  /**
   * Check OTP whether if registered
   */
  const checkOtp = async () => {
    const phone = method.getValues('phone');
    customerInfoSchema.fields.phone
      .validate(phone)
      .then(async () => {
        method.clearErrors('phone');
        if (flowType === 'EC') setOpenOtp(true);
        else {
          setCountdownTimer(resendTimer);
          setInCountdownOTP(true);
          requestOTP();
        }
      })
      .catch((error) => {
        method.setError('phone', error);
        toast.error('Gửi OTP không thành công', TOAST_CONFIG as ToastOptions);
      });
  };

  const confirmOtp = async () => {
    const otpCode = method.getValues('otp');
    const phone = method.getValues('phone');
    if (otpCode) {
      setConfirmLoadingOtp(true);
      try {
        await verifyOtpService({
          phone,
          otp: otpCode,
        });
        toast.success('Xác nhận OTP thành công!', TOAST_CONFIG as ToastOptions);
        verifiedOtpRef.current = true;
        setValidOtp(true);
      } catch {
        toast.error('Mã OTP không hợp lệ!', TOAST_CONFIG as ToastOptions);
      } finally {
        setConfirmLoadingOtp(false);
      }
    } else {
      toast.error('Mã OTP không hợp lệ!', TOAST_CONFIG as ToastOptions);
    }
  };

  /**
   * Submit OTP Code
   */
  const handleSubmitOtp = (val: string) => {
    if (val !== '') {
      setOpenOtp(false);
      verifiedOtpRef.current = true;
    }
  };

  return (
    <>
      <div className="t-customerInfo">
        <div className="t-customerInfo_title">
          <h2>THÔNG TIN KHÁCH HÀNG</h2>
        </div>
        <div className="t-customerInfo_form">
          <Form method={method} submitForm={handleSubmit}>
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label">
                Họ
              </div>
              <div className="t-customerInfo_form_input">
                <Controller
                  control={method.control}
                  name="lastName"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      id="lastName"
                      value={value}
                      placeholder="Nhập họ"
                      type="text"
                      error={error?.message}
                      handleChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label">
                Tên lót
              </div>
              <div className="t-customerInfo_form_input">
                <Controller
                  control={method.control}
                  name="surName"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      id="surName"
                      value={value}
                      placeholder="Nhập tên lót"
                      type="text"
                      error={error?.message}
                      handleChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label">
                Tên
              </div>
              <div className="t-customerInfo_form_input">
                <Controller
                  control={method.control}
                  name="firstName"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      id="firstName"
                      value={value}
                      placeholder="Nhập tên"
                      type="text"
                      error={error?.message}
                      handleChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label">
                Số Điện Thoại
              </div>
              <div className="t-customerInfo_form_input flex">
                <Controller
                  control={method.control}
                  name="phone"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      id="phone"
                      value={value}
                      placeholder="Nhập số điện thoại"
                      type="tel"
                      error={error?.message}
                      handleChange={(event) => {
                        if (event.target.value.match(/\D+/)) {
                          // eslint-disable-next-line no-param-reassign
                          event.target.value = event.target.value.replace(/\D+/g, '');
                        }
                        if (onChange) {
                          onChange(event);
                        }
                      }}
                    />
                  )}
                />
                {
                  validOtp
                    ? (
                      <div className="t-customerInfo_form_check">
                        <img src={checkIcon} alt="icon" />
                      </div>
                    )
                    : (
                      <div className="t-customerInfo_form_btnOtp">
                        {
                          !inCountdownOTP || errorOtp ? (
                            <Button
                              onClick={checkOtp}
                              loading={loadingOtp}
                              modifiers={['otp', 'secondary']}
                            >
                              Gửi OTP
                            </Button>
                          ) : (
                            <Button
                              disabled
                              modifiers={['otp', 'secondary']}
                            >
                              Gửi lại sau
                              {' '}
                              {countdownTimer}
                              {' '}
                              giây
                            </Button>
                          )
                        }
                      </div>
                    )
                }

              </div>
            </div>
            {
              flowType === 'PG' && (
                <div className="t-customerInfo_form_field">
                  <div className="t-customerInfo_form_label">
                    Mã OTP
                  </div>
                  <div className="t-customerInfo_form_input flex">
                    <Controller
                      control={method.control}
                      name="otp"
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          id="otp"
                          value={value}
                          placeholder="Nhập OTP"
                          type="tel"
                          error={error?.message}
                          handleChange={(event) => {
                            if (event.target.value.match(/\D+/)) {
                              // eslint-disable-next-line no-param-reassign
                              event.target.value = event.target.value.replace(/\D+/g, '');
                            }
                            if (onChange) {
                              onChange(event);
                            }
                          }}
                        />
                      )}
                    />
                    {
                      !validOtp
                      && (
                        <div className="t-customerInfo_form_btnOtp">
                          <Button
                            onClick={confirmOtp}
                            modifiers={['otp', 'secondary']}
                            loading={confirmLoadingOtp}
                          >
                            Xác Nhận Mã OTP
                          </Button>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label">
                Email
              </div>
              <div className="t-customerInfo_form_input">
                <Controller
                  control={method.control}
                  name="email"
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      id="email"
                      value={value}
                      placeholder="Nhập Email"
                      type="email"
                      error={error?.message}
                      handleChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label" />
              <div className="t-customerInfo_form_input">
                <Checkbox
                  id="policy"
                  checked={checkPolicy}
                  onChange={() => setCheckPolicy(!checkPolicy)}
                  handleClick={() => setOpenPolicy(true)}
                >
                  <p style={{ textDecoration: 'underline' }}>
                    Tôi đồng ý nhận các thông tin khuyến mại, sự kiện, khảo sát,
                    tư vấn miễn phí từ các nhãn hàng của Mondelēz Việt Nam qua điện thoại,
                    nhắn SMS, email hoặc các nền tảng trực tuyến.
                  </p>
                </Checkbox>
              </div>
            </div>
            <div className="t-customerInfo_form_field">
              <div className="t-customerInfo_form_label" />
              <div className="t-customerInfo_form_submit">
                <Button
                  isSubmit
                  loading={createCustomerState.loading}
                >
                  {
                    flowType === 'PG' ? 'GỬI THÔNG TIN' : 'ĐĂNG NHẬP'
                  }
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <ToastContainer />
      <OtpPopup
        // error={errorOtp}
        handleClose={() => setOpenOtp(false)}
        openPopup={openOtp}
        handleSubmit={(val) => handleSubmitOtp(val)}
      // loading={otpLoading}
      />
      <PolicyPopup
        handleClose={() => setOpenPolicy(false)}
        isOpen={openPolicy}
      />
    </>
  );
};

CustomerInfo.defaultProps = {
  flowType: undefined,
};

export default CustomerInfo;
