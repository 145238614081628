import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getCustomerInformationService } from 'services/auth';
import { getLocalStorage } from 'services/common/storage';
import { FlowType } from 'store/System/type';

const Ending: React.FC = () => {
  const flowType = getLocalStorage('flow') as FlowType;
  const slugId = useParams<{id: string}>();

  useEffect(() => {
    (async () => {
      if (slugId && slugId.id && flowType === 'PG') {
        const data = await getCustomerInformationService(slugId.id);

        (window as any).constellar?.action('ending', data);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-ending">
      <div className="p-ending_heading">
        <h1>Chúc mừng bạn đã tham gia và nhận phần quà từ chương trình</h1>
      </div>
    </div>
  );
};

export default Ending;
