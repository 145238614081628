import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';

import Input from 'components/atoms/Input';

interface OtpInputProps {
  // eslint-disable-next-line no-unused-vars
  handleSubmit?: (arg: string) => void;
}

const OtpInput: React.FC<OtpInputProps> = ({ handleSubmit }) => {
  const numInput = 6;
  const [otp, setOtp] = useState(Array(numInput).fill(''));
  const otpError = false;

  const otpRef = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  useEffect(() => {
    if (otp[0].length > 1) {
      setOtp(otp[0].split(''));
    }
    if (handleSubmit) {
      if (otp.join('').length === numInput) {
        handleSubmit(otp.join(''));
      } else {
        handleSubmit('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    otpRef[0].current?.focus();
    setOtp(Array(numInput).fill(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpError]);

  return (
    <div className="m-otpInput">
      <div className="m-otpInput_wrapper">
        {otp.map((_, idx) => (
          <Fragment key={`item${idx.toString()}`}>
            <Input
              id={`otpInput${idx + 1}`}
              name={`otpInput${idx + 1}`}
              type="number"
              maxLength={1}
              ref={otpRef[idx]}
              value={otp[idx]}
              modifiers="otp"
              handleFocus={({ target }) => target.select()}
              handleChange={({ target: { value } }) => {
                const intValue = parseInt(value, 10);
                const formatedValue = intValue ? String(intValue % 10) : value;

                setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? formatedValue : v)));

                if (value.length > 0) {
                  if (idx < numInput - 1) {
                    otpRef[idx + 1].current?.focus();
                  }
                }
              }}
              onKeyUp={({ key }) => {
                const { current } = otpRef[idx];
                if (!current) {
                  return;
                }
                if (key === 'Backspace' && idx > 0) {
                  setOtp((oldOtp) => oldOtp.map((v, nth) => (idx === nth ? '' : v)));
                  current.value = '';
                  otpRef[idx - 1].current?.focus();
                }
              }}
            />
          </Fragment>
        ))}
      </div>
      {otpError && (
        <div className="m-otpInput_error">
          Mã OTP không hợp lệ. Xin vui lòng nhập lại
        </div>
      )}
    </div>
  );
};

OtpInput.defaultProps = {
  handleSubmit: undefined,
};

export default OtpInput;
