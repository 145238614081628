import * as yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const customerInfoSchema = yup.object().shape({
  firstName: yup.string().required('Tên là bắt buộc'),
  lastName: yup.string().required('Họ là bắt buộc'),
  phone: yup
    .string()
    .required('Số điện thoại là bắt buộc !')
    .min(8, 'Số điện thoại phải có ít nhất 8 ký tự là số')
    .max(20, 'Số điện thoại tối đa 20 ký tự là số')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ !')
    .max(255, 'Nhập tối đa 255 ký tự'),
});

export const loginPgSchema = yup.object().shape({
  supermarket: yup.object().nullable().required('Vui lòng chọn siêu thị'),
  phone: yup
    .string()
    .required('Số điện thoại là bắt buộc !')
    .min(8, 'Số điện thoại phải có ít nhất 8 ký tự là số')
    .max(20, 'Số điện thoại tối đa 20 ký tự là số')
    .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
  password: yup.string().required('Mật khẩu là bắt buộc'),
});
