import React from 'react';

interface TextProps {
}

const Text: React.FC<TextProps> = ({ children }) => (
  <div>{children}</div>
);

Text.defaultProps = {
};

export default Text;
