/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import 'App.scss';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Navigate, Route, RouteProps, Routes,
} from 'react-router-dom';

import MainLayoutContainer from 'containers/mainLayout';
import AppInitializer from 'helpers/appinitializer';
import useConstellar from 'hooks/useConstellar';
import Congratulation from 'pages/Congratulation';
import CustomerInfo from 'pages/CustomerInfo';
import Ending from 'pages/Ending';
import Home from 'pages/Home';
import LoginEc from 'pages/LoginEc';
import LoginPg from 'pages/LoginPg';
import { getTokenSession } from 'services/common/storage';
import { store } from 'store';
import { getProfilePgAction } from 'store/Auth';
import { getSystemAction } from 'store/System';
import { useAppDispatch } from 'store/hooks';
import RedirectToPage from 'utils/RedirectToPage';
import SLUG_PARAMS from 'utils/constants';

const routes = [
  // {
  //   paths: '/',
  //   element: <Home />,
  // },
  {
    paths: SLUG_PARAMS.LOGIN_PG,
    element: <LoginPg />,
  },
  {
    paths: SLUG_PARAMS.LOGIN_EC,
    element: <LoginEc />,
  },
  {
    paths: SLUG_PARAMS.CONGRATULATION,
    element: <Congratulation />,
    isAuth: true,
  },
  {
    paths: `${SLUG_PARAMS.ENDING}/:id`,
    element: <Ending />,
  },
  {
    paths: SLUG_PARAMS.CUSTOMER_INFO,
    element: <CustomerInfo />,
    isAuth: true,
  },
];

interface PrivateRouteProps extends RouteProps {
  outlet: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ outlet }) => {
  const token = getTokenSession();

  if (!token) return <Navigate to={SLUG_PARAMS.LOGIN_PG} />;
  return outlet;
};

const App: React.FC = () => {
  const token = getTokenSession();
  const dispatch = useAppDispatch();
  useConstellar();
  useEffect(() => {
    if (token) {
      dispatch(getProfilePgAction());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    dispatch(getSystemAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <RedirectToPage>
        <Routes>
          <Route
            path="/"
            element={(
              <MainLayoutContainer />
          )}
          >
            {
            routes.map((item, index) => (item.isAuth ? (
              <Route
                path={item.paths}
                key={index.toString()}
                element={(
                  <PrivateRoute
                    outlet={item.element}
                  />
                )}
              />
            ) : (
              <Route
                key={`router-${item.element}-${index.toString()}`}
                path={item.paths}
                element={item.element}
              />
            )))
          }
          </Route>
        </Routes>
      </RedirectToPage>
    </Suspense>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <AppInitializer />
      <App />
    </Router>
  </Provider>
);

export default AppWrapper;
