import React from 'react';

import LoginPGContainer from 'containers/login-pg';

const LoginPg: React.FC = () => (
  <div className="p-loginPg">
    <LoginPGContainer />
  </div>
);

export default LoginPg;
