import React from 'react';

import CongratulationContainer from 'containers/congratulation';

const Congratulation: React.FC = () => (
  <div className="p-congratulation">
    <CongratulationContainer />
  </div>
);

export default Congratulation;
