import React from 'react';

import CustomerInfoTemplate from 'components/templates/CustomerInfo';
import { getLocalStorage } from 'services/common/storage';
import { FlowType } from 'store/System/type';

const CustomerInfo: React.FC = () => {
  const flowType = getLocalStorage('flow') as FlowType;

  return (
    <div className="p-customerInfo">
      <CustomerInfoTemplate
        flowType={flowType || 'EC'}
      />
    </div>
  );
};

export default CustomerInfo;
