import React from 'react';

import CustomerInfoContainer from 'containers/customerInfo';

const LoginEc: React.FC = () => (
  <div className="p-loginEc">
    <CustomerInfoContainer />
  </div>
);

export default LoginEc;
