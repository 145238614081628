import React from 'react';

import CustomerInfo from 'components/templates/CustomerInfo';
import { getLocalStorage } from 'services/common/storage';
import { FlowType } from 'store/System/type';

const CustomerInfoContainer: React.FC = () => {
  const flowType = getLocalStorage('flow') as FlowType;

  return (
    <CustomerInfo flowType={flowType || 'EC'} />
  );
};

export default CustomerInfoContainer;
