import React, { forwardRef, ChangeEvent } from 'react';

import mapModifiers from 'utils/functions';

interface CheckboxProps {
  id: string;
  name?: string;
  checked?: boolean;
  error?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  modifiers?: string;
  handleClick?: () => void;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({
    id,
    name = '',
    checked,
    onChange,
    handleClick,
    children,
    modifiers,
    error,
  }, ref) => (
    <div className={mapModifiers('a-checkbox', modifiers)}>
      <div className="a-checkbox_main">
        <input
          className="a-checkbox_main_input"
          type="checkbox"
          id={id}
          ref={ref}
          checked={checked}
          name={name}
          onChange={onChange}
        />
        <span className="a-checkbox_square" />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="a-checkbox_square_label" htmlFor={id} />
        <p onClick={handleClick} className="a-checkbox_main_label">
          {children}
        </p>
      </div>
      {
        error !== ''
        && <div className="a-checkbox_error">{error}</div>
      }
    </div>
  ),
);

export default Checkbox;
