import React from 'react';
import QRCode from 'react-qr-code';

import boxGift from 'assets/images/box.png';
import cardGift from 'assets/images/card-congrate.png';
import Button from 'components/atoms/Button';
import { FlowType } from 'store/System/type';

interface CongratulationProps {
  flowType?: FlowType;
  qrCode?: string;
  handleClick: () => void;
}

const Congratulation: React.FC<CongratulationProps> = ({
  handleClick, flowType, qrCode,
}) => (
  <>
    <div className="t-congratulation">
      <div className="t-congratulation_notice">
        Chúc mừng bạn
        <span>Đã nhận được 1 phần quà từ Cosy!</span>
      </div>
      {
          flowType === 'EC'
            ? (
              <div className="t-congratulation_gift">
                <div className="t-congratulation_gift_image">
                  <img src={cardGift} alt="cardGift" />
                </div>
                <div className="t-congratulation_desc">
                  Hệ thống sẽ gửi tin nhắn  xác nhận phần quà  vào số điện thoại quý khách đăng ký
                </div>
              </div>
            ) : (
              <>
                <div className="t-congratulation_gift">
                  <div className="t-congratulation_gift_image">
                    <img src={boxGift} alt="cardGift" />
                  </div>
                </div>
                <div className="t-congratulation_qr">
                  <div className="desc">
                    Quý khách vui lòng
                    <span>
                      <strong>
                        Quét mã QR
                      </strong>
                      {' '}
                      để xác nhận quà tặng
                    </span>
                  </div>
                  {
                    qrCode
                    && (
                    <div className="t-congratulation_qr_code">
                      <QRCode width={150} value={qrCode} />
                    </div>
                    )
                  }
                </div>
                {/* <div className="t-congratulation_checkbox">
                  <Checkbox
                    id="policy"
                    checked={checkPolicy}
                    onChange={() => setCheckPolicy(!checkPolicy)}
                  >
                    Tôi đã đọc và đồng ý với các điều khoản và điều kiện
                  </Checkbox>
                </div> */}
              </>
            )
        }
      <div className="t-congratulation_button">
        <Button onClick={handleClick}>
          HOÀN THÀNH
        </Button>
      </div>
    </div>
  </>
);

Congratulation.defaultProps = {
  flowType: undefined,
};

export default Congratulation;
