/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Select, { components, Props as SelectProps } from 'react-select';

import mapModifiers from 'utils/functions';

export interface OptionType {
  label: string;
  value: string;
}

interface PullDownProps extends SelectProps {
  isDisabled?: boolean;
  placeholder?: string;
  name: string;
  options: OptionType[];
  value?: OptionType | null;
  isSearchable?: boolean;
  isRtl?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isHideIconDropdown?: boolean;
  innerRef?: React.MutableRefObject<any>;
  error?: string;
  isError?: boolean;
  label?: string;
  modifier?: 'secondary' | 'datepicker';
  handleSelect?: (val: OptionType) =>void;
}

const PullDown: React.FC<PullDownProps> = ({
  isDisabled,
  placeholder,
  name,
  value,
  options,
  isSearchable,
  isRtl,
  isLoading,
  isClearable,
  isHideIconDropdown,
  innerRef,
  error,
  isError,
  label,
  modifier,
  onChange,
  ...rest
}) => {
  const [selectedOption] = useState<OptionType | null>(null);

  const DropdownIndicator = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <div className="m-pulldown_icon-indicator" />
    </components.DropdownIndicator>
  );

  const CustomStyles = {
    option: (provided: any, state: { isFocused: boolean; isSelected: boolean; }) => ({
      ...provided,
      border: 'none',
      padding: 12,
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      transition: '0.4s ease',
      // textTransform: 'uppercase',
      color: (state.isFocused || state.isSelected)
        ? '#2F447F' : '#434549',
      fontWeight: 400,
      fontSize: 18,
      '&:active': {
        backgroundColor: 'transparent',
      },
    }),
    control: (base: any) => ({
      ...base,
      height: '50px',
      paddingLeft: '16px',
      color: '#01193E',
      fontWeight: '400',
      borderRadius: '5px',
      border: `1px solid ${isError ? '#FF0000' : '#8C8C8C'} !important`,
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (base: any) => ({
      ...base,
      color: isError ? '#FF0000' : 'rgb(51, 51, 51, 0.6)',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '28px',
      // textTransform: 'uppercase',
    }),
    singleValue: (provided: any, state: { isDisabled: boolean; }) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return {
        opacity,
        transition,
        fontWeight: 400,
        color: '#000000',
        fontSize: 18,
        // textTransform: 'uppercase',
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      display: 'flex',
    }),
    indicatorsContainer: (provided: any) => ({ ...provided, padding: 0 }),
    dropdownIndicator: (provided: any) => ({ ...provided, padding: 0, display: isHideIconDropdown ? 'none' : 'block' }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      top: '115%',
      fontWeight: 700,
    }),
    menuList: (provided: any) => ({ ...provided }),
  };

  const CustomTheme = (theme: any) => ({
    ...theme,
    borderRadius: 1,
    colors: {
      ...theme.colors,
    },
  });

  return (
    <div className={mapModifiers('m-pulldown', modifier)}>
      {
        label && <div className="m-pulldown_label">{label}</div>
      }
      <Select
        className="m-pulldown"
        classNamePrefix="m-pulldown"
        styles={CustomStyles}
        components={{ DropdownIndicator }}
        defaultValue={selectedOption}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        name={name}
        value={value}
        isRtl={isRtl}
        isSearchable={false}
        isLoading={isLoading}
        isClearable={false}
        theme={CustomTheme}
        onChange={onChange}
        {...rest}
      />
      {error && (
      <div className="m-pulldown-errorMessage">
        {error}
      </div>
      )}
    </div>
  );
};

export default PullDown;
