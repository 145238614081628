import React, { useEffect, useState } from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import OtpInput from 'components/molecules/OtpInput';
import Popup from 'components/organisms/Popup';

interface OtpPopupProps {
  openPopup: boolean;
  error?: string;
  handleClose?: () => void;
  handleSubmit?: (arg: string) => void;
  handleResend?: () => void;
}

const OtpPopup: React.FC<OtpPopupProps> = ({
  openPopup, error,
  handleClose, handleSubmit, handleResend,
}) => {
  const resendTimer = 60;
  const [otpValue, setOtpValue] = useState('');
  const [inCountdownOTP, setInCountdownOTP] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(0);

  useEffect(() => {
    if (openPopup) {
      setCountdownTimer(resendTimer);
      setInCountdownOTP(true);
    }
  }, [openPopup]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);
    if (countdownTimer === 0) {
      setInCountdownOTP(false);
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [countdownTimer]);

  const handleResendOtp = () => {
    setInCountdownOTP(true);
    setCountdownTimer(resendTimer);
    if (handleResend) handleResend();
  };

  return (
    <div className="t-otpPopup">
      <Popup
        isOpenModal={openPopup}
        handleClose={handleClose}
        modifiers="otp"
        title="Quý Khách Vui Lòng Nhập Mã OTP Vừa Gửi Về Số Điện Thoại Đăng Ký"
      >

        <OtpInput handleSubmit={(val) => setOtpValue(val)} />
        {
          error !== ''
          && (
          <div className="t-otpPopup_error">
            {error}
          </div>
          )
        }
        <div className="t-otpPopup_reset">
          <div className="label">
            <Text>
              Chưa nhận được mã ?
            </Text>
          </div>
          {
            inCountdownOTP
              ? (
                <Text>
                  Thử lại sau:
                  {' ( '}
                  <strong>
                    {countdownTimer}
                    {' ) '}
                    s
                  </strong>
                </Text>
              )
              : (
                <div
                  className="cursor"
                  onClick={handleResendOtp}
                >
                  <Text>
                    <strong> Gửi lại mã</strong>
                  </Text>
                </div>
              )
          }

        </div>
        <div className="t-otpPopup_button">
          <Button
            onClick={() => handleSubmit && handleSubmit(otpValue)}
          >
            XÁC THỰC
          </Button>
        </div>
      </Popup>
    </div>
  );
};

OtpPopup.defaultProps = {
  handleClose: undefined,
  handleSubmit: undefined,
  handleResend: undefined,
  error: undefined,
};

export default OtpPopup;
