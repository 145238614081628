import React from 'react';

import Popup from 'components/organisms/Popup';

interface PolicyPopupProps {
  isOpen: boolean;
  handleClose?: () => void;
}

const PolicyPopup: React.FC<PolicyPopupProps> = ({ isOpen, handleClose }) => (
  <div className="t-policyPopup">
    <Popup
      title="THÔNG BÁO VỀ QUYỀN RIÊNG TƯ"
      isOpenModal={isOpen}
      modifiers="policy"
      handleClose={handleClose}
      hasCloseButton
    >
      <div className="t-policyPopup_line">
        <h3>Giới thiệu</h3>
        <p>
          Tại Mondelēz International, chúng tôi đánh giá cao
          sự tin tưởng của quý vị khi chia sẻ Dữ liệu Cá nhân của quý vị
          với chúng tôi và nhận ra rằng quý vị có thể quan tâm tới thông
          tin đã ung cấp cho chúng tôi và về cách chúng tôi xử lý thông tin đó.
        </p>
        <p>
          Nếu quý vị đang đọc Thông báo về quyền riêng tư này
          {' '}
          <strong>
            (&ldquo;Thông báo về quyền riêng tư&ldquo;)
          </strong>
          {' '}
          thì có thể là do quý vị
          đã truy cập trang web, ứng dụng di động, hồ sơ truyền thông xã hội,
          bất kỳ dịch vụ trực tuyến nào hoặc tương tác với
          chúng tôi tại các điểm hội họp hoặc thông qua các hoạt động
          (tất cả chúng đều được giới thiệu dưới đây là các “trang web”)
          được điều hành bởi bất kỳ thực thể nào của tập đoàn Mondelēz International:
          bao gồm
          {' '}
          <strong>
            Công ty Cổ phần Mondelez Kinh Đô Việt Nam (“MDLZ” hoặc “chúng tôi”)
          </strong>
          {' '}
          hoặc, bởi vì quý vị có thể đã liên hệ trực tiếp với chúng tôi qua điện thoại
          hoặc bằng văn bản.
        </p>
        <p>
          Thông báo về quyền riêng tư này nêu ra cơ sở chúng tôi sử dụng,
          lưu trữ và xử lý Dữ liệu Cá nhân của quý vị
          {' '}
          <strong>(&ldquo;Dữ liệu cá nhân&ldquo;)</strong>
          {' '}
          khi quý vị truy cập các website hoặc liên hệ với chúng tôi.
        </p>
      </div>
      <div className="t-policyPopup_line">
        <h3>Xử lý dữ liệu MDLZ</h3>
        <p>
          MDLZ thu thập và sử dụng Dữ liệu
          Cá nhân mà quý vị cung cấp hoặc chúng tôi thu thập về quý
          vị khi quý vị điều hướng qua Trang web của chúng tôi cho
          mục đích quảng cáo, cung cấp cho quý vị các sản phẩm và dịch vụ
          của chúng tôi, liên hệ với quý vị để tiếp thị hoặc các mục
          đích thương mại khác, trả lời các yêu cầu của quý vị về sản phẩm của chúng tôi,
          tạo điều kiện cho quý vị tham gia vào các ưu đãi và khuyến mãi,
          xử lý mọi khiếu nại về sản phẩm cũng như phân tích
          và cải thiện chất lượng sản phẩm hoặc dịch vụ của chúng tôi.
        </p>
        <p>
          Việc quý vị truy cập và sử dụng trang web của chúng tôi
          tuân theo các điều khoản và điều kiện của Thông báo về quyền
          riêng tư và tất cả các luật hiện hành. Việc xử lý Dữ liệu Cá nhân
          của quý vị sẽ bị giới hạn ở những gì cần thiết liên quan
          đến các mục đích được mô tả trong Thông báo về quyền riêng tư này.
          Chúng tôi sẽ yêu cầu sự đồng thuận của quý vị cho một
          số xử lý để quý vị có quyền kiểm soát thông tin chúng tôi xử lý
          và giữ của quý vị. Trái lại, chúng tôi sẽ chỉ xử lý
          Dữ liệu Cá nhân của quý vị nếu cần thiết để thực hiện hợp đồng với
          quý vị, để tuân thủ nghĩa vụ pháp lý của MDLZ, cho các mục đích lợi ích
          hợp pháp của MDLZ hoặc các mục đích khác được pháp luật cho phép.
        </p>
        <p>
          Chúng tôi thường xử lý Dữ liệu Cá nhân của quý vị ở nơi của quý vị nhưng nó cũng
          có thể được lưu trữ trong các hệ thống trên toàn
          thế giới và có thể được xử lý bởi các nhà cung
          cấp dịch vụ bên thứ ba thay mặt chúng tôi.
          Các hệ thống và nhà cung cấp này có thể được đặt tại
          các lãnh thổ không cung cấp mức độ bảo vệ tương tự
          với quyền riêng tư như tại địa điểm của quý vị.
          Trong trường hợp đó, MDLZ sẽ sử dụng các nỗ lực hợp lý
          để cung cấp các biện pháp bảo vệ đầy đủ cho Dữ liệu
          Cá nhân của quý vị tương đương hợp lý với một trong những quốc gia gốc.
          Quý vị có thể tìm kiếm thêm thông tin và thực hiện
          các quyền truy cập, chỉnh sửa, hủy bỏ, phản đối và tính di động
          của dữ liệu đối với Dữ liệu Cá nhân của quý vị,
          bằng cách nhấp vào tính năng Liên hệ với chúng tôi.
        </p>
        <p>
          Chúng tôi có thể thay đổi Thông báo về
          quyền riêng tư này theo từng thời kỳ bằng cách đăng phiên
          bản cập nhật của Thông báo về quyền riêng tư trên trang web
          của chúng tôi. Chúng tôi sẽ thông báo về bất kỳ thay đổi
          quan trọng nào để quý vị có thể hiểu rõ những thay đổi được bổ sung.
          Vui lòng truy cập Thông báo về quyền riêng tư này để được thông báo
          về cách chúng tôi sử dụng Dữ liệu Cá nhân của quý vị.
        </p>
        <strong>Thông báo về quyền riêng tư này vào Tháng 02 năm 2020. </strong>
      </div>
      <div className="t-policyPopup_line">
        <h3>Chúng tôi thu thập Dữ liệu Cá nhân nào từ quý vị?</h3>
        <p>
          Loại thông tin và Dữ liệu Cá nhân chúng tôi thu tập về
          quý vị khi quý vị ghé thăm trang web của chúng tôi
          hoặc tương tác với chúng tôi bao gồm như sau:
        </p>
        <ul>
          <li>
            Thông tin chi tiết cá nhân về quý vị hoặc do chính quý vị cung
            cấp - như là tên, địa chỉ bưu chính hoặc các chi tiết liên lạc khác,
            chẳng hạn như số điện thoại, địa chỉ email, yêu cầu của quý vị,
            bất ký khiếu nại nào quý vị có thể có hay bất kỳ dữ liệu nào khác
            chúng tôi nhận được nếu chúng tôi liên lạc với quý vị (bao gồm qua
            điện thoại, email, trực tuyến hay phương tiện truyền thông xã hội)
            và bất kỳ thông tin nào quý vị cung cấp, ví dụ như: khi quý vị đăng
            ký với trang web, đăng ký nhận tin tức, tham gia các ưu đãi
            và khuyến mãi của chúng tôi, cung cấp phản hồi của khách hàng,
            trả lời khảo sát hoặc mua sản phẩm/dịch vụ của chúng tôi;
          </li>
          <li>
            Thông tin thanh toán và mua hàng - bao gồm dữ liệu để mua hàng như
            là chi tiết tài khoản thanh toán, số thẻ tín dụng và thẻ ghi nợ,
            ngày hết hạn, địa chỉ giao hàng và thanh toán, chi tiết về sản phẩm đã mua;
          </li>
          <li>
            Thông tin về ưu đãi – các thông tin về sở thích,
            nhu cầu, quyền lợi, ý kiến cá nhân và hành vi
            của quý vị (ví dụ: liên quan tới trang web,
            sản phẩm và dịch vụ của chúng tôi);
          </li>
          <li>
            Thông tin tài khoản đăng nhập - bao gồm mọi thông tin
            bắt buộc để quý vị thiết lập một tài
            khoản người dùng, chẳng hạn như tên người
            dùng, mật khẩu, câu hỏi và câu trả lời bảo mật;
          </li>
          <li>
            Thông tin được thu thập tự động – bao gồm thông tin về thiết bị,
            địa chỉ IP và trình duyệt của quý vị, ví dụ như
            khi chúng tôi sử dụng cookie của chính chúng tôi
            hay cookie thuộc sở hữu của bên thứ ba trong
            trang web trong trang web của chúng tôi, tập tin
            chỉ báo và các công nghệ tương tự thu thập thông
            tin về việc sử dụng trang web của quý vị trên trang
            của chúng tôi hoặc về sự tương tác của quý vị với
            chúng tôi qua email. Xem chính sách Cookie của chúng
            tôi để biết thêm thông tin. Cookie bao gồm những Cookie
            được sử dụng trên trang web của chúng tôi có thể bị vô hiệu hóa
            thông qua cài đặt trong trình duyệt của quý vị nếu quý vị
            vô hiệu hóa tất cả cookie, quý vị có thể không có khả năng
            truy cập hoặc có đầy đủ các chức năng của trang web chúng tôi.
            Vô hiệu hóa cookie không xóa cookie khỏi thiết bị hiện tại của quý vị,
            xóa cookie cần phải được hoàn thành thủ công thông qua các tùy chọn
            phù hợp trong menu trình duyệt của quý vị.
          </li>
        </ul>
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Tại sao chúng tôi lại thu thập Dữ liệu Cá nhân của quý vị?
        </h3>
        <p>
          Chúng tôi thu thập Dữ liệu Cá nhân của quý vị cho các mục
          đích dưới đây và trên bất kỳ cơ sở hợp pháp nào khác.
          Vui lòng lưu ý rằng nếu chúng tôi đã lưu giữ thông tin về quý vị,
          chúng tôi có thể sử dụng thông tin ấy cho cùng mục đích.
        </p>
        <p>
          Vì lợi ích thương mại hợp pháp: chúng tôi có thể sử dụng Dữ liệu
          Cá nhân của quý vị (trên cả cơ sở tổng hợp và cơ sở cá nhân),
          chẳng hạn như chi tiết liên hệ, tài khoản và dữ liệu nhận dạng
          điện tử của quý vị, thông tin liên quan đến giao dịch mua hàng của quý
          vị trong cửa hàng và trực tuyến của chúng tôi, cho mục đích
          cung cấp sản phẩm và dịch vụ của chúng tôi, duy trì và cập nhật hồ sơ,
          quản trị, xác minh thông tin, quảng cáo, tiếp thị và để phân tích
          và nâng cao chất lượng sản phẩm hoặc dịch vụ của chúng tôi,
          cũng như hiểu quý vị là khách hàng.
        </p>
        <p>
          Điều này cũng có nghĩa là chúng tôi phân tích thông tin mà quý vị
          cung cấp cho chúng tôi cùng với việc quý vị sử dụng
          Trang web hoặc các sản phẩm và dịch vụ của chúng tôi
          và chúng tôi sử dụng tất cả thông tin này để cải thiện và
          để cung cấp cho quý vị trải nghiệm người dùng tốt hơn
          (ví dụ: chúng tôi phân tích các trang quý vị truy cập,
          sản phẩm và dịch vụ nào quý vị sử dụng và thích, cách quý vị
          sử dụng các sản phẩm và dịch vụ này).
        </p>
        <p>
          Chúng tôi cũng có thể sử dụng Dữ liệu Cá nhân của quý vị,
          cho các lợi ích thương mại hợp pháp như là để tạo số liệu
          thống kê tổng hợp về người dùng các sản phẩm và dịch vụ của
          chúng tôi; để hỗ trợ an ninh và phòng chống gian lận;
          cho các mục đích toàn vẹn hệ thống; để nhắc nhở quý vị về
          giỏ hàng của quý vị hoặc gửi cho quý vị một xác nhận đặt hàng;
          để tạo điều kiện cho hoạt động kinh doanh của chúng tôi,
          để vận hành các chính sách và thủ tục của công ty; để cho phép chúng
          tôi thực hiện các giao dịch của công ty, như bất kỳ việc sáp nhập,
          bán, sắp xếp lại, chuyển nhượng doanh nghiệp hoặc tài sản MDLZ,
          mua lại, phá sản hoặc các điều tương tự, để tuân thủ các nghĩa vụ
          pháp lý của chúng tôi; và cho các mục đích kinh doanh hợp pháp
          khác được pháp luật hiện hành cho phép, dựa vào đó chúng tôi sẽ
          cung cấp thông tin liên quan tại thời điểm đó.
        </p>
        <p>
          Để thực hiện các điều khoản và điều kiện của hợp đồng:
          mua sản phẩm hoặc dịch vụ của chúng tôi hoặc phát triển một
          cuộc thi, cuộc đọ sức hoặc quảng cáo mà quý vị có thể tham gia,
          để tiến hành quảng cáo và liên hệ với quý vị nếu quý vị trở
          thành người chiến thắng, để trở thành người chiến thắng có thể
          trả lời bất kỳ yêu cầu thông tin nào được thực hiện bằng các
          tính năng Liên hệ với chúng tôi
        </p>
        <p>
          Chúng tôi cũng có thể sử dụng thông tin của quý vị dựa trên sự đồng ý của
          quý vị, trong trường hợp được cấp, ví dụ như được
          cấp cho mục đích tiếp thị và điều chỉnh thông tin liên lạc của chúng
          tôi với quý vị dựa trên hồ sơ của quý vị qua email, SMS, phương tiện
          điện tử khác, và/hoặc để theo dõi và phân tích sở thích của quý vị
          cho mục đích tìm hiểu của người tiêu dùng và tiếp thị cá nhân.
          Quý vị có thể rút lại sự đồng ý của mình bất cứ lúc nào;
          xem Quý vị có quyền gì đối với Dữ liệu Cá nhân mà chúng tôi có về quý vị?
        </p>
        <p>
          Để tuân thủ các nghĩa vụ pháp lý của chúng tôi, như duy trì hồ sơ kinh
          doanh phù hợp, xử lý các khiếu nại đối với dịch vụ khách hàng
          của chúng tôi, quản lý các khiếu nại về sự an toàn, chất lượng
          hoặc sự lành mạnh của sản phẩm, tuân thủ các yêu cầu hợp pháp
          của cơ quan công quyền và với luật áp dụng và quy định hiện
          hành hoặc theo yêu cầu của pháp luật.
        </p>
        <p />
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Chúng tôi sẽ lưu trữ Dữ liệu Cá nhân trong bao lâu?
        </h3>
        <p>
          Chúng tôi sẽ chỉ lưu giữ Dữ liệu Cá nhân của quý vị miễn là đáp ứng
          cần thiết mục đích mà quý vị cung cấp hoặc do
          chúng tôi thu thập (ví dụ: thời gian cần thiết để chúng tôi
          trả lời các truy vấn hoặc giải quyết vấn đề).
        </p>
        <p>
          Do đó, chúng tôi có thể giữ lại Dữ liệu Cá nhân của quý vị
          trong một khoảng thời gian hợp lý sau lần tương tác cuối cùng
          của quý vị với chúng tôi. Khi Dữ liệu Cá nhân mà chúng tôi thu
          thập không còn cần thiết, chúng tôi sẽ hủy hoặc xóa dữ liệu đó
          một cách an toàn. Chúng tôi có thể, thay vì hủy hoặc xóa
          Dữ liệu Cá nhân của quý vị, làm cho nó ẩn danh để nó không
          thể được liên kết hoặc theo dõi lại cho quý vị. Trong một số
          trường hợp nhất định, chúng tôi có thể có nghĩa vụ pháp lý hoặc
          quy định yêu cầu chúng tôi giữ lại các hồ sơ cụ thể trong một
          khoảng thời gian nhất định.
        </p>
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Chúng tôi có thể chia sẻ Dữ liệu Cá nhân với những ai?
        </h3>
        <p>
          Chúng tôi có thể chia sẻ Dữ liệu Cá nhân của quý vị với:
        </p>
        <ul>
          <li>
            Các thực thể của MDLZ. Dữ liệu Cá nhân của quý vị có thể được chia
            sẻ giữa các công ty MDLZ, nơi mà có thể sử dụng
            Dữ liệu Cá nhân của quý vị như được mô tả trong
            Thông báo về quyền riêng tư này.
          </li>
          <li>
            Nhà cung cấp và xử lý dịch vụ. Chúng tôi có thể thay mặt nhà
            cung cấp, đại lý, nhà cung cấp dịch vụ và các đơn vị liên kết
            của bên thứ ba cung cấp dịch vụ cho chúng tôi, như hỗ trợ cho
            các hoạt động nội bộ của trang web, Ứng dụng di động hoặc cửa
            hàng trực tuyến (bao gồm nhà cung cấp dịch vụ truyền thông xã hội,
            bộ xử lý thanh toán, đại lý kích hoạt thương hiệu, quảng cáo,
            tiếp thị và các cơ quan quảng cáo, người cung cấp phân tích dữ liệu,
            cố vấn chuyên nghiệp (ví dụ: kế toán, nhân viên ngân hàng,
            cố vấn kinh doanh, người thu nợ, luật sư) và các bên thứ ba chúng
            tôi sử dụng để gửi đơn đặt hàng của quý vị đến địa chỉ của quý vị,
            chẳng hạn như nhà cung cấp chuyển phát nhanh bưu chính) hoặc để
            xử lý kỹ thuật (ví dụ: dịch vụ hosting hoặc lưu trữ dữ liệu) hoặc
            cho các dịch vụ quản lý quan hệ khách hàng, cũng như các dịch vụ
            hỗ trợ quảng cáo hoặc sản phẩm ngoại tuyến có liên quan,
            (ví dụ: các cuộc thi, rút thăm trúng thưởng và ban tổ chức cuộc thi,
            người chiến thắng sẽ thu hút nhà cung cấp, các cơ quan quản lý sự kiện,
            nhà cung cấp giải thưởng hoặc bồi thường) và các dịch vụ liên quan khác.
          </li>
          <li>
            Khi cung cấp dịch vụ, họ có thể thay mặt chúng tôi truy cập,
            nhận, lưu trữ hoặc xử lý Dữ liệu Cá nhân. Hợp đồng của chúng tôi
            với các nhà cung cấp dịch vụ này không cho phép sử dụng thông tin
            của quý vị cho mục đích riêng của họ, bao gồm cả mục đích tiếp thị của họ.
            Theo các yêu cầu pháp lý hiện hành, chúng tôi thực hiện các bước hợp
            lý về mặt thương mại để yêu cầu các bên thứ ba bảo vệ đầy đủ
            Dữ liệu Cá nhân của quý vị và chỉ xử lý theo hướng dẫn của chúng tôi.
          </li>
          <li>
            Đối tác. Đôi khi, chúng tôi có thể cung cấp cho quý vị một dịch vụ
            hoặc ứng dụng hợp tác với các đối tác (ví dụ: nhà đồng
            tài trợ hoặc người cấp phép, người cấp phép hoặc nhà phân
            phối các sản phẩm có thương hiệu của chúng tôi). Do đó,
            chúng tôi có thể cần tiết lộ Dữ liệu Cá nhân của quý vị cho
            các đối tác đó. Theo các yêu cầu pháp lý hiện hành,
            chúng tôi thực hiện các bước hợp lý về mặt thương mại để
            yêu cầu các bên thứ ba bảo vệ đầy đủ Dữ liệu Cá nhân của quý
            vị và chỉ xử lý theo hướng dẫn của chúng tôi hoặc với tư cách
            là các bên đồng kiểm soát. Trong những trường hợp đó,
            việc tiết lộ Dữ liệu Cá nhân của quý vị với các đối tác
            thứ ba diễn ra dựa trên sự đồng ý của quý vị hoặc yêu cầu
            của quý vị, nếu có liên quan, chúng tôi sẽ thông báo rõ ràng
            cho quý vị về việc chia sẻ và quý vị sẽ có lựa chọn không
            tham gia hoặc mặt khác để phản đối chia sẻ như vậy.
          </li>
          <li>
            Bên thứ ba theo yêu cầu pháp luật. Chúng tôi có thể phải
            công khai Dữ liệu Cá nhân của quý vị nếu chúng tôi cho
            rằng phải làm điều đó theo luật pháp, hoặc điều đó là cần
            thiết trong việc tuân thủ các thủ tục tố tụng; khi chúng tôi
            cho rằng việc công khai Dữ liệu Cá nhân của quý vị cho các cơ
            quan chấp pháp là cần thiết và phù hợp, ví dụ như để phục vụ
            điều tra hành vi lừa đảo hoặc bị nghi ngờ là lừa đảo hoặc hành
            vi vi phạm pháp luật, phá hoại an ninh, hay vi phạm Thông báo
            về quyền riêng tư này; để trả lời các phản hồi về chúng tôi hoặc
            nhân viên của chúng tôi; và để bảo vệ quyền lợi, tài sản, hoặc an
            toàn cá nhân của MDLZ, của các khách hàng, nhân viên, nhà thầu hoặc công chúng.
          </li>
          <li>
            Bên thứ ba trong giao dịch của doanh nghiệp. Ngoài ra,
            thông tin về khách hàng và người mua hàng của chúng tôi,
            bao gồm Dữ liệu Cá nhân, có thể được công khai
            như một phần của việc sát nhập, bán hàng, tái tổ chức,
            chuyển nhượng tài sản và giao dịch của MDLZ, việc tiếp nhận,
            phá sản, hoặc các sự kiện tương tự.
          </li>
        </ul>
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Truyền Dữ liệu Cá nhân của quý vị trên phạm vi quốc tế
        </h3>
        <p>
          Tính chất toàn cầu của công ty chúng tôi khiến
          cho Dữ liệu Cá nhân của quý vị có thể được công khai
          trong tập đoàn Mondelēz International.
        </p>
        <p>
          Chúng tôi cũng sử dụng các nhà cung cấp bên thứ ba hỗ trợ cung cấp
          các dịch vụ kinh doanh. Các bên thứ ba này có thể truy cập
          hoặc điều hành Dữ liệu Cá nhân của quý vị, nhưng họ luôn luôn phải
          tuân theo các hướng dẫn và quan hệ hợp đồng. Trong trường hợp các
          bên thứ ba này có trụ sở ở những vùng lãnh thổ mà ở đó không thể
          đảm bảo mức độ bảo mật tương đương với mức được áp dụng ở nước quý vị,
          chúng tôi sẽ tiến hành các bước phù hợp để xác minh rằng Dữ liệu
          Cá nhân của quý vị vẫn đang được bảo vệ ở mức phù hợp.
        </p>
      </div>
      <div className="t-policyPopup_line">
        <h3>Chúng tôi đảm bảo an toàn cho Dữ liệu Cá nhân của quý vị bằng cách nào?</h3>
        <p>
          Chúng tôi sử dụng một loạt các tiêu chuẩn, công nghệ và quy trình vật lý,
          kỹ thuật và quản trị an ninh để tránh cho Dữ liệu
          Cá nhân của quý vị bị mất mát, sử dụng sai mục đích,
          thay đổi, bị hủy hay thiệt hại với mức độ hợp lý dựa
          trên độ nhạy cảm của thông tin.
        </p>
        <p>
          Chúng tôi tiến hành các bước để hạn chế việc truy cập vào
          Dữ liệu Cá nhân của quý vị đối với những người cần truy
          cập thông tin theo các mục đích được liệt kê trong bản
          Thông báo về quyền riêng tư này. Ngoài ra, chúng tôi cũng
          nỗ lực để bảo đảm bên thứ ba khi xử lý Dữ liệu Cá nhân
          của quý vị phải đảm bảo tính bảo mật và tính toàn bộ của dữ
          liệu của quý vị một cách an toàn nhất.
        </p>
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Quý vị sẽ có những quyền hạn như thế nào đối với Dữ liệu Cá nhân mà chúng tôi đang giữ?
        </h3>
        <p>
          Quý vị sẽ có những quyền sau:
        </p>
        <ul>
          <li>
            Được nhận một bản sao Dữ liệu Cá nhân của bản
            cùng với thông tin về nền tảng mà Dữ liệu Cá nhân
            của quý vị đang được xử lý;
          </li>
          <li>
            Được sửa Dữ liệu Cá nhân chưa chính xác
            (bao gồm quyền được hoàn thiện Dữ liệu Cá nhân còn thiếu);
          </li>
          <li>
            Được yêu cầu chúng tôi xóa Dữ liệu Cá nhân của
            quý vị nếu luật pháp quy định;
          </li>
          <li>
            Được yêu cầu chúng tôi hạn chế việc xử
            lý Dữ liệu Cá nhân ở phần mà quý vị yêu cầu chúng tôi hoặc:
            <p>
              - Khi tính chính xác của Dữ liệu Cá nhân bị tranh cãi;
            </p>
            <p>
              - Quá trình xử lý là trái pháp luật, nhưng quý vị phản đối việc xóa Dữ liệu Cá nhân;
            </p>
            <p>
              - Chúng tôi sẽ không yêu cầu Dữ liệu Cá nhân của quý vị vì mục đích
              mà nó đã được thu thập, nhưng chúng tôi sẽ yêu cầu
              vì mục đích cài đặt, thực hành hoặc trả lời các yêu cầu pháp lý; hoặc
            </p>
            <p>
              - Quý vị yêu cầu việc xử lý được xác minh trên cơ sở lợi ích hợp pháp.
            </p>
          </li>
        </ul>
        <p>
          Trong trường hợp quý vị không hài lòng về cách chúng tôi
          xử lý Dữ liệu Cá nhân của quý vị, quý vị có thể gửi phản hồi
          bằng cách tiếp cận chúng tôi thông qua thông tin chi tiết trong
          phần Liên hệ với chúng tôi.
        </p>
        <p>
          Nếu quý vị có bất kỳ câu hỏi nào liên quan đến quá trình xử lý
          Dữ liệu Cá nhân của quý vị hay Thông báo về quyền riêng tư này
          hoặc nếu quý vị muốn thực hành bất kỳ quyền hạn nào đã được đề cập ở trên,
          làm ơn hay liên hệ với chúng tôi thông qua thông tin chi tiết trong phần
          Liên hệ với chúng tôi. Quý vị có thể thu hồi thỏa thuận về việc nhận thư
          thông báo hay tiếp thị truyền thông bất kỳ lúc nào, hoàn toàn miễn phí bằng
          cách làm theo các hướng dẫn trong bất kỳ tiếp thị truyền thông nào.
          Quý vị cũng có thể kiểm soát các chế độ ưu đãi này trong phần cài đặt
          hồ sơ cá nhân phần được áp dụng.
        </p>
        <p />
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Truyền thông mạng xã hội, các Website, hay Dịch vụ trực tuyến
        </h3>
        <p>
          Khi quý vị tham gia vào bất kỳ hồ sơ MDLZ nào của chúng tôi hoặc bất cứ nơi
          nào MDLZ hiện diện trong các diễn đàn truyền thông mạng
          xã hội như Facebook, Twitter, Yammer, Pinterest, Instagram,
          LinkedIn, Youtube…, quý vị sẽ thấy quen thuộc và dễ hiểu được
          các công cụ mà các trang này cung cấp để quý vị có thể lựa chọn
          cách chia sẻ Dữ liệu Cá nhân trong hồ sơ truyền thông mạng
          xã hội của quý vị.
        </p>
        <p>
          Do bên thứ ba áp dụng các chính sách hoặc việc thực hiện
          chính sách quyền riêng tư theo các diễn đàn này, vì thế chúng tôi
          khuyến khích quý vị nên đọc các Thông báo về quyền riêng tư được áp dụng,
          các điều khoản sử dụng và thông tin liên quan đến việc sử dụng Dữ liệu Cá
          nhân của quý vị trong môi trường truyền thông mạng xã hội.
        </p>
        <p>
          Sau đó các trang của
          chúng tôi có đường dẫn tới các webstite của bên thứ ba;
          nếu quý vị vào các đường dẫn này thì quý vị sẽ bị thoát ra khỏi website và
          các ứng dụng di động của chúng tôi. Trong khi các website của bên thứ ba
          này được lựa chọn cẩn thận, MDLZ cũng không thể chấp nhận độ tin cậy trong
          việc các tổ chức này sử dụng Dữ liệu Cá nhân của quý vị. Để biết
          thêm thông tin chi tiết, vui lòng tham khảo phần Thông báo về quyền
          riêng tư Dữ liệu của các bên thứ ba và thông báo quyền
          bảo mật của các website mà quý vị đang truy cập (nếu có).
        </p>
        <p>
          Trừ khi được quy định khác đi, Thông báo về quyền riêng tư
          này sẽ không áp dụng đối với các diễn đàn truyền thông mạng
          xã hội và website/ứng dụng của bên thứ ba không thuộc quyền
          sở hữu của MDLZ khi họ quản trị và xử lý Dữ liệu Cá nhân của quý vị.
        </p>
        <p>
          Một vài nhà cung cấp dịch vụ trực tuyến của chúng tôi
          sẽ hỗ trợ phương pháp giải tích trực tuyến, quảng cáo đích,
          video nhúng và các nội dung khác, tương tác và khảo sát
          truyền thông mạng xã hội. và những nhà cung cấp này có
          thể sẽ dùng những cookies, tập tin chỉ báo và các công
          nghệ lưu trữ khác để thu thập và nhân thông tin từ trang
          của chúng tôi và bất kỳ đâu trên mạng Internet. Ví dụ:
          với việc quảng cáo đích, chúng tôi có thể điều chỉnh việc
          truyền tải và nội dung các quảng cáo của chúng tôi và tư
          liệu khác trên trang của chúng tôi hoặc bên thứ ba cũng như
          các dịch vụ trực tuyến dựa trên những lần truy cập trước của
          quý vị đến trang của chúng tôi.
        </p>
        <p>
          Việc theo dõi được mô tả ở trên có thể cho phép các nhà cung cấp
          dịch vụ trực tuyến thu thập thông tin về tương tác của quý vị
          tới trang (bao gồm địa chỉ IP) và những thông tin có thể kết hợp
          với thông tin họ đã thu thập tại các thời điểm khác thông qua
          các nguồn khác (ví dụ: Google có thể kết nối hoạt động của quý
          vị trên các thiết bị đa phương tiện nếu như quý vị có tài khoản Google),
          hoặc với thông tin mà chúng tôi cung cấp cho họ như là địa chỉ email.
          Việc sử dụng các công nghệ này cho phép họ trợ giúp
          chúng tôi xác định các nhóm khách hàng đích dựa trên việc
          sử dụng trang và các đặc tính của đối tượng (như là tuổi, giới tính),
          truyền tải nội dung quảng cáo theo yêu cầu của khách hàng,
          đo lường tính hiệu quả của quảng cáo (ví dụ: bằng cách báo cáo
          động thái của mọi người sau khi xem một quảng cáo bất kỳ),
          ước lượng việc sử dụng trang của chúng tôi và các trang khác,
          cung cấp các dịch vụ khác liên quan đến hoạt động của website và sử dụng internet.
        </p>
        <p>
          Các bên thứ ba này có thể sẽ chuyển thông tin mà họ thu thập
          được cho chúng tôi theo yêu cầu của luật pháp,
          hoặc ở những phần mà chúng tôi được ủy quyền xử lý thông tin.
        </p>
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Chúng tôi có thể xử lý Dữ liệu Cá nhân của Trẻ em như thế nào?
        </h3>
        <p>
          Tại MDLZ, chúng tôi không tin vào việc tiếp
          thị trực tiếp cho trẻ em dưới 12 tuổi. Do đó, các Trang
          web của chúng tôi không dành cho trẻ em dưới 12 tuổi.
        </p>
        <p>
          Chúng tôi cũng rất coi trọng việc bảo vệ quyền riêng tư của trẻ em.
          Các cá nhân chưa đủ tuổi theo luật áp dụng đối với từng
          Trang web nhằm mục đích cung cấp sự đồng thuận, nên có sự đồng
          ý của cha mẹ/người giám hộ trước khi cung cấp bất kỳ Dữ liệu
          Cá nhân nào cho chúng tôi thông qua trang web. Sau khi thu thập,
          nếu chúng tôi xác định rằng người dùng chưa đủ tuổi hoặc chưa
          cung cấp sự đồng thuận của cha mẹ/người giám hộ trước khi cung
          cấp bất kỳ Dữ liệu Cá nhân nào, chúng tôi sẽ không sử dụng hoặc
          lưu trữ Dữ liệu Cá nhân của người đó mà không có sự đồng thuận
          của cha mẹ/người giám hộ. Tuy nhiên, nếu không có sự đồng thuận đó,
          trẻ/cá nhân không thể truy cập vào một số hoạt động nhất định.
          Tuy nhiên, trong một số trường hợp nhất định, chúng tôi có thể
          duy trì và sử dụng thông tin đó (theo phần còn lại của Thông báo
          về quyền riêng tư này và luật áp dụng) để thông báo và nhận
          được sự đồng ý từ phụ huynh/người giám hộ và để đảm bảo an toàn,
          bảo mật, trách nhiệm pháp lý, lưu giữ hồ sơ và các mục đích
          khác được phép theo luật hiện hành. Phụ huynh/ người giám hộ
          có thể xem xét, xóa, thay đổi hoặc từ chối thu thập hoặc sử
          dụng thêm Dữ liệu Cá nhân của con họ bằng cách liên hệ với
          chúng tôi như được nêu trong phần Liên hệ với chúng tôi,
          bao gồm tên, địa chỉ và địa chỉ email của con họ.
        </p>
      </div>
      <div className="t-policyPopup_line">
        <h3>
          Liên Hệ với Chúng Tôi
        </h3>
        <p>
          Nếu quý vị có thắc mắc hoặc yêu cầu liên quan đến
          Thông báo về quyền riêng tư này hoặc nếu quý vị muốn
          thực hiện các quyền liên quan đến Dữ liệu Cá nhân của mình,
          vui lòng liên hệ.
        </p>
        <p>
          <strong>Việt Nam</strong>
        </p>
        <p>
          <strong>Đường dây nóng:</strong>
          {' '}
          <a href="tel:19001889">19001889</a>
        </p>
        <p>
          <strong>Email-ID:</strong>
          {' '}
          <a href="mailto:customercare.mkd@mdlz.com">customercare.mkd@mdlz.com</a>
        </p>
      </div>
    </Popup>
  </div>
);

export default PolicyPopup;
