import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import loginPgService, { getProfilePgService } from 'services/auth';
import { LoginPgParamsTypes, LoginPgResponseTypes, ProfilePgTypes } from 'services/auth/type';
import { initialTokenSession } from 'services/common/storage';

interface UserState {
  data?: LoginPgResponseTypes;
  profile?: ProfilePgTypes;
}

const initialState: UserState = {
  data: undefined,
  profile: undefined,
};

export const getProfilePgAction = createAsyncThunk<
  ProfilePgTypes,
  void,
  { rejectValue: ErrorResponse }
>('profile/getMemberProfileAction', async (_, { rejectWithValue }) => {
  try {
    const res = await getProfilePgService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const getLoginPgAction = createAsyncThunk<
LoginPgResponseTypes,
  LoginPgParamsTypes,
  { rejectValue: ErrorResponse }
>('loginReducer/getLoginPgAction',
  async (params: LoginPgParamsTypes, { rejectWithValue }) => {
    try {
      const result = await loginPgService(params);
      if (result.token) {
        initialTokenSession({ accessToken: result.token });
      }
      return result;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  });

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLoginPgAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
    builder.addCase(getProfilePgAction.fulfilled, ($state, action) => {
      $state.profile = action.payload;
    });
  },
});

export default authSlice.reducer;
