import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Congratulation from 'components/templates/Congratulation';
import { getLocalStorage } from 'services/common/storage';
import { FlowType } from 'store/System/type';
import SLUG_PARAMS, { LOCAL_PARAMS } from 'utils/constants';

const CongratulationContainer: React.FC = () => {
  const flowType = getLocalStorage('flow') as FlowType;
  const navigate = useNavigate();
  const uuid = getLocalStorage(LOCAL_PARAMS.UUID);
  useEffect(() => {
    if (!uuid) {
      navigate(SLUG_PARAMS.CUSTOMER_INFO);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const baseURL = window.location.origin;

  return (
    <Congratulation
      flowType={flowType || 'EC'}
      qrCode={`${baseURL}${SLUG_PARAMS.ENDING}/${uuid}`}
      handleClick={() => navigate(SLUG_PARAMS.CUSTOMER_INFO)}
    />
  );
};

export default CongratulationContainer;
