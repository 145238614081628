import { SystemDataTypes } from './type';

import axiosInstance from 'services/common/instance';

const getSystemService = async (): Promise<SystemDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data.data;
};

export default getSystemService;
