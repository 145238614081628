import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FlowType } from './type';

import { setLocalStorage } from 'services/common/storage';
import getSystemService from 'services/system';
import { SystemDataTypes } from 'services/system/type';

interface SystemState {
  flow: FlowType;
  system: any;
}

const initialState: SystemState = {
  flow: 'EC',
  system: undefined,
};

export const getSystemAction = createAsyncThunk<
  SystemDataTypes,
  string | undefined,
  { rejectValue: ErrorResponse }
>('general/getSystemAction', async (_, { rejectWithValue }) => {
  try {
    const res = await getSystemService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    updateFlowAction($state, action: PayloadAction<FlowType>) {
      $state.flow = action.payload;
      setLocalStorage('flow', action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      $state.system = action.payload;
    });
  },
});

export const { updateFlowAction } = systemSlice.actions;

export default systemSlice.reducer;
